import clsx from 'clsx'

import { ContentfulRichText } from '@/components/Contentful/common/ContentfulRichText'
import { Warning } from '@/components/Warning'

import { useEntry } from '../App.hooks'

type Props = {
	className?: string
	title?: string
}

export function AppFeatures({ className, title = 'App Features' }: Props) {
	const entry = useEntry()

	if ('error' in entry) {
		return <Warning>{entry.error}</Warning>
	}

	const { appFeaturesCollection } = entry.data ?? {}

	if (!appFeaturesCollection?.items.length) {
		return null
	}

	return (
		<section className={clsx(className, 'grid grid-cols-1 gap-6 mt-10 justify-items-start w-full')}>
			<h3 className="text-2xl font-medium text-bc-black">{title}</h3>
			{appFeaturesCollection?.items.map((item) => (
				<div key={item?.title} className="flex flex-col justify-start">
					<p className="text-lg font-normal text-bc-black">{item?.title}</p>
					<ContentfulRichText field={{ data: item?.description }} />
				</div>
			))}
		</section>
	)
}
